import { GeneralErrorBoundary } from "~/components/error-boundary.tsx";
import { CheckboxField, ErrorList, Field } from "~/components/forms.tsx";
import { StatusButton } from "~/components/ui/status-button.tsx";
import { login, requireAnonymous } from "~/utils/auth.server.js";
// import { checkHoneypot } from "~/utils/honeypot.server.ts";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";
import { checkHoneypot } from "~/utils/honeypot.server.ts";
import { appDetails, useIsPending } from "~/utils/misc.tsx";
import { EmailSchema, PasswordSchema } from "~/utils/user-validation.ts";
import { handleNewSession } from "./login.server.ts";
import img from "/img/undraw_login_re_4vu2.svg?url";
// import {
//   ProviderConnectionForm,
//   providerNames,
// } from "~/utils/connections.tsx";

const LoginFormSchema = z.object({
  email: EmailSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional().default("/app"),
  remember: z.boolean().optional(),
});

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.formData();
  checkHoneypot(formData);
  const submission = await parseWithZod(formData, {
    schema: (intent) =>
      LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null };

        const session = await login(data);
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid email or password",
          });
          return z.NEVER;
        }

        return { ...data, session };
      }),
    async: true,
  });

  if (submission.status !== "success" || !submission.value.session) {
    return json(
      { result: submission.reply({ hideFields: ["password"] }) },
      { status: submission.status === "error" ? 400 : 200 }
    );
  }

  const { session, remember, redirectTo } = submission.value;

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
  });
}

export const meta: MetaFunction = () => {
  return [{ title: `Login | ${appDetails.title}` }];
};

export default function LoginPage() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") ?? "/app";

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="bg-white">
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-6 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex flex-col items-center">
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-foreground">
                Welcome Back!
              </h2>
              <h3>
                {appDetails.title} by {appDetails.companyShortName}
              </h3>
            </div>

            <div className="mt-10">
              <div>
                <Form
                  method="POST"
                  {...getFormProps(form)}
                  className="flex flex-col gap-2"
                >
                  <HoneypotInputs />
                  <Field
                    labelProps={{ children: "Email" }}
                    inputProps={{
                      ...getInputProps(fields.email, { type: "text" }),
                      autoFocus: true,
                      className: "lowercase",
                      autoComplete: "off",
                    }}
                    errors={fields.email.errors}
                  />
                  <Field
                    labelProps={{ children: "Password" }}
                    inputProps={{
                      ...getInputProps(fields.password, {
                        type: "password",
                      }),
                      autoComplete: "current-password",
                    }}
                    errors={fields.password.errors}
                  />

                  <div className="flex justify-between">
                    <CheckboxField
                      labelProps={{
                        htmlFor: fields.remember.id,
                        children: "Remember me",
                      }}
                      buttonProps={getInputProps(fields.remember, {
                        type: "checkbox",
                      })}
                      errors={fields.remember.errors}
                    />
                    <div>
                      <Link
                        to="/forgot-password"
                        className="text-body-xs font-semibold"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <input
                    {...getInputProps(fields.redirectTo, { type: "hidden" })}
                  />
                  <ErrorList errors={form.errors} id={form.errorId} />

                  <div className="flex items-center justify-between gap-6 pt-3">
                    <StatusButton
                      className="w-full"
                      status={isPending ? "pending" : form.status ?? "idle"}
                      type="submit"
                      disabled={isPending}
                    >
                      Log in
                    </StatusButton>
                  </div>
                </Form>
                {/* <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
                  {providerNames.map((providerName) => (
                    <li key={providerName}>
                      <ProviderConnectionForm
                        type="Login"
                        providerName={providerName}
                        redirectTo={redirectTo}
                      />
                    </li>
                  ))}
                </ul> */}
                <div className="flex items-center justify-center gap-2 pt-6">
                  <span className="text-muted-foreground">New here?</span>
                  <Link
                    to={
                      redirectTo
                        ? `/signup?${encodeURIComponent(redirectTo)}`
                        : "/signup"
                    }
                  >
                    Create an account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-scale-down"
            src={img}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
